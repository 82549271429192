import { Controller } from "stimulus"
import $ from "jquery"
import { fire } from '@rails/ujs'

export default class extends Controller {
  static targets = ["tableBody", "form", "editButton", "saveButton", "cancelButton"]

  initialize () {
  }

  connect() {
    this.cnt = 0
  }

  saveItems(e) {
    fire(this.formTarget, 'submit')
    e.preventDefault()
  }
}
