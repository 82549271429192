import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    this.toggleHeadOrg(this.data.has('orgtype') && (this.data.get('orgtype') == 1) || this.data.get('orgtype') == 2)
  }

  doOrgTypeSelect(event) {
    const typeId = event.target.value
    this.toggleHeadOrg(
      typeId == 2
    )
  }

  toggleHeadOrg(isFil) {
    if (isFil) {
      $('#head_org').show()
    } else {
      $('#head_org').hide()
    }
  }

}
