import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [ 'compare', 'component', 'vocRegion', 'vocZone', 'month', 'year' ]

  connect() {
    let compare = this.data.get('compare')
    this.toggleComponent(compare === 'component')
    this.toggleVocZone(compare === 'voc_zone_id')
    this.toggleVocRegion(compare === 'voc_region_id')
    this.toggleMonth(compare === 'month')
    this.toggleYear(compare === 'year')
  }

  doCompareSelect(event) {
    const compare = event.target.value
    this.toggleComponent(compare === 'component')
    this.toggleVocZone(compare === 'voc_zone_id')
    this.toggleVocRegion(compare === 'voc_region_id')
    this.toggleMonth(compare === 'month')
    this.toggleYear(compare === 'year')
  }

  toggleComponent(isSelected) {
    if (isSelected) {
      $(this.componentTarget).show()
    } else {
      $(this.componentTarget).hide()
    }
  }
  toggleVocZone(isSelected) {
    if (isSelected) {
      $(this.vocZoneTarget).show()
    } else {
      $(this.vocZoneTarget).hide()
    }
  }
  toggleVocRegion(isSelected) {
    if (isSelected) {
      $(this.vocRegionTarget).show()
    } else {
      $(this.vocRegionTarget).hide()
    }
  }
  toggleMonth(isSelected) {
    if (isSelected) {
      $(this.monthTarget).show()
    } else {
      $(this.monthTarget).hide()
    }
  }
  toggleYear(isSelected) {
    if (isSelected) {
      $(this.yearTarget).show()
    } else {
      $(this.yearTarget).hide()
    }
  }

}
