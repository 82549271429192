import { Controller } from "stimulus"
import $ from "jquery"


export default class extends Controller {
  connect() { }

  toggleCheckAll() {
    if (this.allIsChecked()) {
      this.checkingCells().prop('checked', false)
    } else {
      this.checkingCells().prop('checked', true)
    }
  }

  allIsChecked() {
    var result = true
    this.checkingCells().each(function() {
      if ( !$(this).prop('checked') ) {
        result = false
        return false
      }
    });
    return result
  }

  checkingCells() {
    return $( this.data.get('selector') );
  }

}
