import { Controller } from "stimulus"
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import $ from "jquery"

import regression from 'regression';
import { round, determinationCoefficient } from 'math-utils';

Chart.plugins.unregister(ChartDataLabels)
var plugin = {
  id: 'savetopng',
  afterRender: function(chart, options) {
    let hidden = options.hidden
    if (hidden){
      $(hidden).val(chart.toBase64Image())
    }
    //$("#test_img_" + chartName).attr('src', chart.toBase64Image())
  }
}
Chart.plugins.register(plugin)

export default class extends Controller {

  connect() {
    let ctx = this.element.getContext('2d')

    let labels = JSON.parse(this.data.get('labels'))
    let title = this.data.get('title')
    let color = JSON.parse(this.data.get('color'))
    let color2 = JSON.parse(this.data.get('color2')) || []
    let data = JSON.parse(this.data.get('data'))
    let data2 = JSON.parse(this.data.get('data2')) || []
    let datasetLabel = this.data.get('datasetLabel')
    let datasetLabel2 = this.data.get('datasetLabel2') || []
    let type = this.data.get('type')

    let trendline = this.data.get('trend')
    let regrType = 'linear'

    let datasets = [{
      label: datasetLabel,
      data: data,
      borderColor: color,
      fill: false,
      tension: 0.1
    }]

    if (trendline) {
      let regrData = []
      for (let i = 0; i < data.length; i++) {
        regrData.push([i, (+data[i]) || 0])
      }
      let regr = regression[regrType](regrData)

      let calcRegrData = [];
      for (let i = 0; i < data.length; i++) { // Fill the array with calculated values
        calcRegrData.push(regr.points[i][1]);
      }

      datasets.push({
        label: regr.r2 ? `${datasetLabel} - тренд (R2 = ${regr.r2})` : `${datasetLabel} - тренд (R2 = ${round(determinationCoefficient(regrData, regr.points), 2)})`,
        data: calcRegrData,
        borderColor: color,
        borderDash: [5, 5],
        fill: false,
        tension: 0.1,
        pointRadius: 0,
      })
    }

    if(data2.length){
      datasets.push(
        {
          label: datasetLabel2,
          data: data2,
          borderColor: color2,
          fill: false,
          tension: 0.1
        }
      )

      if (trendline) {
        let regrData = []
        for (let i = 0; i < data2.length; i++) {
          regrData.push([i, (+data2[i]) || 0])
        }
        let regr = regression[regrType](regrData)
        let calcRegrData = [];
        for (let i = 0; i < data2.length; i++) { // Fill the array with calculated values
          calcRegrData.push(regr.points[i][1]);
        }

        datasets.push({
          label: regr.r2 ? `${datasetLabel2} - тренд (R2 = ${regr.r2})` : `${datasetLabel2} - тренд (R2 = ${round(determinationCoefficient(regrData, regr.points), 2)})`,
          data: calcRegrData,
          borderColor: color2,
          borderDash: [5, 5],
          pointRadius: 0,
          fill: false,
          tension: 0.1,
        })
      }
    }

    this.chart = new Chart(ctx, {
      type: type,
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        title: {
          display: true,
          text: title
        },
        spanGaps: true,
        plugins: {
          savetopng: {
            hidden: this.data.has("hidden") ? this.data.get("hidden") : '',
          }
        }
      }
    })
  }

  disconnect() {
    this.chart.destroy()
  }
}
