import { Controller } from "stimulus"
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import $ from "jquery"

Chart.plugins.unregister(ChartDataLabels)

var plugin = {
  id: 'savetopng',
  afterRender: function(chart, options) {
    var hidden = options.hidden
    if (hidden){
      $(hidden).val(chart.toBase64Image())
    }
    //$("#test_img").attr('src', chart.toBase64Image())
  }
}
Chart.plugins.register(plugin)

export default class extends Controller {
    connect() {
      let ctx = this.element.getContext('2d')

      let labels = JSON.parse(this.data.get('labels')) || []
      let colors = JSON.parse(this.data.get('colors')) || []
      let data = JSON.parse(this.data.get('data')) || []
      let title = this.data.get('title')
      let datasetLabel = this.data.get('datasetLabel')
      let datasetLabel2 = this.data.get('datasetLabel2')
      let suggestedMax = this.data.get('suggestedMax')
      let suggestedMin = this.data.get('suggestedMin')

      let colors2 = JSON.parse(this.data.get('colors2')) || []
      let data2 = JSON.parse(this.data.get('data2')) || []

      let datasets = [{
        label: datasetLabel,
        data: data,
        backgroundColor: colors
      }]
      if(data2.length){
        datasets.push(
          {
            label: datasetLabel2,
            data: data2,
            backgroundColor: colors2
          }
        )
      }

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          maintainAspectRatio: false,
          title: {
            display: true,
            text: title
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMax: suggestedMax,
                suggestedMin: suggestedMin
              }
            }]
          },
          plugins: {
            savetopng: {
              hidden: this.data.has("hidden") ? this.data.get("hidden") : ''
            }
          }
        }
      })
    }

  disconnect() {
    this.chart.destroy()
  }
}
