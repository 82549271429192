import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery";

export default class extends Controller {
  connect() {
    //console.log('init')
    StimulusReflex.register(this)
  }
  loadDonorOrganizations(event) {

    let id = $(event.target).val()
    if (!id) { return }

    this.stimulate('Donor::Request#organizations_details', { serializeForm: false }, id)
  }
}
