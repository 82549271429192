
import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  static targets = ["donor"]

  connect() {
    StimulusReflex.register(this)
  }

  doDonorDetail(e) {
    const donorId = this.donorTarget.value
    let requestId = this.data.get('request')
    this.stimulate('DonorData#detail', donorId, requestId)
  }
}
