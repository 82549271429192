import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [ 'type', 'dt', 'range', 'month', 'year', 'components' ]

  connect() {
    let type = this.data.get('type')
    this.toggleRange(type === 'range')
    this.toggleDate(type === 'daily')
    this.toggleMonth(type === 'monthly')
    this.toggleYear(type === 'monthly' || type === 'annual')
    this.toggleComponents(type === 'range' || type === 'monthly' || type === 'annual')
  }

  doTypeSelect(event) {
    const type = event.target.value
    this.toggleRange(type === 'range')
    this.toggleDate(type === 'daily')
    this.toggleMonth(type === 'monthly')
    this.toggleYear(type === 'monthly' || type === 'annual')
    this.toggleComponents(type === 'range' || type === 'monthly' || type === 'annual')
  }

  toggleRange(isSelected) {
    if (isSelected) {
      $(this.rangeTarget).show()
    } else {
      $(this.rangeTarget).hide()
    }
  }

  toggleDate(isSelected) {
    if (isSelected) {
      $(this.dtTarget).show()
    } else {
      $(this.dtTarget).hide()
    }
  }

  toggleMonth(isSelected) {
    if (isSelected) {
      $(this.monthTarget).show()
    } else {
      $(this.monthTarget).hide()
    }
  }

  toggleYear(isSelected) {
    if (isSelected) {
      $(this.yearTarget).show()
    } else {
      $(this.yearTarget).hide()
    }
  }

  toggleComponents(isSelected) {
    if (isSelected) {
      $(this.componentsTarget).show()
    } else {
      $(this.componentsTarget).hide()
    }
  }
}
